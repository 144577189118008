<template>
  <article class="content-section section-margin">
    <header class="section-header">
      <h4 class="title">신청현황</h4>
    </header>
    <div class="kb-table-list-container">
      <div class="list-top pt-0">
        <div class="top-column"><span class="subtext">전체 {{items.length}}건</span></div>
        <div class="top-column">
          <!--                <a href="" class="link">신청내역 바로가기 <i class="icon-link"></i></a>-->
          <router-link to="/daily/history" class="more-link">신청내역 바로가기 <i class="icon-link"></i></router-link>
        </div>
      </div>
      <div class="kb-table kb-table-bordered kb-table-bordered-min">
        <table>
          <colgroup>
            <col style="width:83px" />
            <col style="width:auto" />
            <col style="width:213px" />
            <col style="width:181px" />
            <col style="width:98px" />
            <col style="width:248px" />
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">구분</span></th>
            <th><span class="th-title">시설명</span></th>
<!--            <th><span class="th-title">상세</span></th>-->
            <th><span class="th-title">신청기간</span></th>
            <th><span class="th-title">신청일시</span></th>
            <th><span class="th-title">신청상태</span></th>
            <th class="cell-center ps-0"><span class="th-title">기능</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item) in items" :key="item.trnctAplySn">
            <td><span class="td-text">연수원</span></td>
            <td><strong class="td-text">{{ item.trnctNm }}</strong></td>
<!--            <td><span class="td-text">203동 1201호</span></td>-->
            <td><span class="td-text">{{ item.rsvtAplyDd }}-{{ item.rsvtAplyDdEnd }}</span></td>
            <td><span class="td-text">{{ item.regDt }}</span></td>
            <td><strong class="td-text text-yellow">{{ item.stbyYn == 'Y' ? `대기(순번 : ${item.stbySeq + 2})` : '신청완료'}}</strong></td>

            <td class="ps-0">
              <div class="td-cell td-actions" v-if="checkThreeDay(item)">
                <button type="button" class="kb-btn kb-btn-outline kb-btn-radius-s" @click="detailTrcntAply(item)"><strong class="text">상세 ・ 수정</strong></button>
                <button type="button" class="kb-btn kb-btn-outline kb-btn-radius-s" @click="openCancelPop(item)"><strong class="text text-gray">신청취소</strong></button>
              </div>
              <div v-else-if="new Date() > new Date(item.rsvtAplyDd) " class="td-actions">
                <span>-</span>
              </div>
              <div v-else class="td-actions">
                <button class="kb-btn kb-btn-outline rounded-lg" type="button" @click="alertThreeday"><strong class="text">상세ㆍ수정</strong></button>
                <button class="kb-btn kb-btn-outline rounded-lg" type="button" @click="alertThreeday"><strong class="text">취소</strong></button>
              </div>

            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </article>

  <!-- begin:popup-container 개인팝업 -->
  <div class="popup-container" :class="{'is-active': data.isPersonalPop}">
    <!-- popup -->
    <div class="popup" id="popup-facility-k08">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <form action="">
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">연수원 개인 신청내역</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <div class="facility-resve">
              <!-- facility-resve-section -->
              <div class="facility-resve-section">
                <div class="kb-table kb-table-striped">
                  <table>
                    <colgroup>
                      <col />
                    </colgroup>
                    <thead>
                    <tr>
                      <th><span class="th-title">구분</span></th>
                      <th><span class="th-title">신청자 정보</span></th>
                      <th><span class="th-title">연수원</span></th>
                      <th><span class="th-title">입소일</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td><span class="td-text">{{ updateTrnctAply.prsnBrcYn == 'Y' ? '개인' : '부점' }}</span></td>
                      <td><span class="td-text">{{ updateTrnctAply.aplcntDeptNm }} ・ {{ updateTrnctAply.aplcntJbgd }} ・ {{ updateTrnctAply.aplcntNm }}</span></td>
                      <td><span class="td-text">{{ updateTrnctAply.trnctNm }}</span></td>
                      <td><span class="td-text">{{ updateTrnctAply.rsvtAplyDd }}  ({{ getDayOfWeek(updateTrnctAply.rsvtAplyDd) }})</span></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- facility-resve-section -->
              <div class="facility-resve-section section-divider">
                <div class="kb-form-fields">
                  <!-- kb-form-row:숙실 형태 -->
                  <div class="kb-form-row kb-form-field-period">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">숙실 형태</span>
                      </label>
                    </div>
                    <div class="kb-form-column flex-fill">
                      <div class="kb-form-check-group">
                        <div class="kb-form-check kb-form-check-inline">
                          <input type="radio" disabled v-model="updateTrnctAply.roomTy" class="kb-form-check-input" id="chk_group01_01" name="chk_group01" value="OR" />
                          <label for="chk_group01_01" class="kb-form-check-label"><span class="text">원룸</span></label>
                        </div>
                        <div class="kb-form-check kb-form-check-inline">
                          <input type="radio" disabled v-model="updateTrnctAply.roomTy" class="kb-form-check-input" id="chk_group01_02" name="chk_group01" value="TR" />
                          <label for="chk_group01_02" class="kb-form-check-label"><span class="text">투룸</span></label>
                        </div>
                        <div class="kb-form-check kb-form-check-inline">
                          <input type="radio" disabled v-model="updateTrnctAply.roomTy" class="kb-form-check-input" id="chk_group01_03" name="chk_group01" value="LTR" />
                          <label for="chk_group01_03" class="kb-form-check-label"><span class="text">안식년 (투룸)</span></label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label" style="display: flex;align-items: center;">
                        <span class="kb-form-label-text">입소일</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <div style="display: flex;align-items: center;">
                        <kb-calender v-model="schedule" :trnct-mst-sn="updateTrnctAply.trnctMstSn" :room-ty="updateTrnctAply.roomTy" v-on:update:modelValue="changeTrnctDt"></kb-calender>
                      </div>
                    </div>
                  </div>

                  <!-- kb-form-row:이용기간 선택 -->
                  <div class="kb-form-row kb-form-field-period">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">이용기간 선택</span>
                      </label>
                    </div>
                    <div class="kb-form-column flex-fill">
                      <div class="kb-form-dropdown" :class="{'is-active': toggles.periodOpen}">
                        <button type="button" class="kb-form-dropdown-btn" @click="openPeriod"><span class="text">숙박날짜</span><i class="icon-arrow"></i></button>
                        <div class="kb-form-dropdown-options">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li class="dropdown-option-item" v-for="item in usePeridOption" :key="item.value">
                                <a href="javascript:" class="dropdown-option-link" @click="selectUsePeriod(item)">
                                  <span class="dropdown-option-text">{{ item.label }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="kb-form-column">
                      <span>{{data.display}}</span>
                    </div>
                  </div>
                  <!-- kb-form-row:비상 연락처 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">비상 연락처</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input type="tel"  maxlength="12" v-model.trim="updateTrnctAply.emerCt" class="kb-form-control" placeholder="비상 연락처를 입력하세요." />
                    </div>
                  </div>
                  <!-- kb-form-row:차량번호 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">차량번호</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input type="text" v-model="updateTrnctAply.carNo" class="kb-form-control" placeholder="차량번호를 입력하세요." />
                    </div>
                  </div>
                  <!-- kb-form-row:입소자 명단 1 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">입소자 명단 1</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input type="text" v-model="updateTrnctAply.aplyDtl[0].cpnnNm" class="kb-form-control" placeholder="이름을 입력하세요" />
                    </div>
                    <div class="kb-form-column">
                      <div class="kb-form-dropdown" :class="{'is-active': toggles.aply1}">
                        <button type="button" class="kb-form-dropdown-btn" @click="openAply(1)"><strong class="text">{{ getRelName(updateTrnctAply.aplyDtl[0].aplcntRel) }}</strong><i class="icon-arrow"></i></button>
                        <div class="kb-form-dropdown-options">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li class="dropdown-option-item" v-for="item in familyList" :key="item.code">
                                <a href="javascript:" class="dropdown-option-link" @click="selectAply(0,item.code)">
                                  <span class="dropdown-option-text">{{ item.label }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- kb-form-row:입소자 명단 2 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">입소자 명단 2</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input type="text" v-model="updateTrnctAply.aplyDtl[1].cpnnNm" class="kb-form-control" placeholder="이름을 입력하세요" />
                    </div>
                    <div class="kb-form-column">
                      <div class="kb-form-dropdown" :class="{'is-active': toggles.aply2}">
                        <button type="button" class="kb-form-dropdown-btn" @click="openAply(2)"><strong class="text">{{ getRelName(updateTrnctAply.aplyDtl[1].aplcntRel) }}</strong><i class="icon-arrow"></i></button>
                        <div class="kb-form-dropdown-options">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li class="dropdown-option-item" v-for="item in familyList" :key="item.code">
                                <a href="javascript:" class="dropdown-option-link" @click="selectAply(1,item.code)">
                                  <span class="dropdown-option-text">{{ item.label }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- kb-form-row:입소자 명단 3 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">입소자 명단 3</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input type="text" v-model="updateTrnctAply.aplyDtl[2].cpnnNm" class="kb-form-control" placeholder="이름을 입력하세요" />
                    </div>
                    <div class="kb-form-column">
                      <div class="kb-form-dropdown" :class="{'is-active': toggles.aply3}">
                        <button type="button" class="kb-form-dropdown-btn" @click="openAply(3)"><strong class="text">{{ getRelName(updateTrnctAply.aplyDtl[2].aplcntRel) }}</strong><i class="icon-arrow"></i></button>
                        <div class="kb-form-dropdown-options">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li class="dropdown-option-item" v-for="item in familyList" :key="item.code">
                                <a href="javascript:" class="dropdown-option-link" @click="selectAply(2,item.code)">
                                  <span class="dropdown-option-text">{{ item.label }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- kb-form-row:입소자 명단 4 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">입소자 명단 4</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input type="text" v-model="updateTrnctAply.aplyDtl[3].cpnnNm" class="kb-form-control" placeholder="이름을 입력하세요" />
                    </div>
                    <div class="kb-form-column">
                      <div class="kb-form-dropdown" :class="{'is-active': toggles.aply4}">
                        <button type="button" class="kb-form-dropdown-btn" @click="openAply(4)"><strong class="text">{{ getRelName(updateTrnctAply.aplyDtl[3].aplcntRel) }}</strong><i class="icon-arrow"></i></button>
                        <div class="kb-form-dropdown-options">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li class="dropdown-option-item" v-for="item in familyList" :key="item.code">
                                <a href="javascript:" class="dropdown-option-link" @click="selectAply(3,item.code)">
                                  <span class="dropdown-option-text">{{ item.label }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- kb-form-row:입소자 명단 5 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">입소자 명단 5</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input type="text" v-model="updateTrnctAply.aplyDtl[4].cpnnNm" class="kb-form-control" placeholder="이름을 입력하세요" />
                    </div>
                    <div class="kb-form-column">
                      <div class="kb-form-dropdown" :class="{'is-active': toggles.aply5}">
                        <button type="button" class="kb-form-dropdown-btn" @click="openAply(5)"><strong class="text">{{ getRelName(updateTrnctAply.aplyDtl[4].aplcntRel) }}</strong><i class="icon-arrow"></i></button>
                        <div class="kb-form-dropdown-options">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li class="dropdown-option-item" v-for="item in familyList" :key="item.code">
                                <a href="javascript:" class="dropdown-option-link" @click="selectAply(4,item.code)">
                                  <span class="dropdown-option-text">{{ item.label }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- kb-form-row:이용 인원 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">이용 인원</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <p class="kb-form-text">{{ updateTrnctAply.aplyPeopl }}명</p>
                    </div>
                  </div>
                  <!-- kb-form-row:특이사항 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">특이사항</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <textarea class="kb-form-control" v-model="updateTrnctAply.dmndCn" placeholder="특이사항을 입력하세요"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-outline kb-btn-lg" type="button" @click="closePop('personal')"><span class="text">닫기</span></button>
            <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="setUpdateTrnctAply"><span class="text">수정</span></a>
          </div>
        </form>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" type="button" @click="data.isPersonalPop = false"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- end:popup-container -->

  <!-- begin:popup-container 부점팝업 -->
  <div class="popup-container" :class="{'is-active': data.isStorePop}">
    <!-- popup -->
    <div class="popup" id="popup-facility-k07">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <form action="">
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">연수원 부점 신청내역</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <div class="facility-resve">
              <!-- facility-resve-section -->
              <div class="facility-resve-section">
                <div class="kb-table kb-table-striped">
                  <table>
                    <colgroup>
                      <col />
                    </colgroup>
                    <thead>
                    <tr>
                      <th><span class="th-title">구분</span></th>
                      <th><span class="th-title">신청자 정보</span></th>
                      <th><span class="th-title">연수원</span></th>
                      <th><span class="th-title">입소일</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td><span class="td-text">{{ updateTrnctAply.prsnBrcYn == 'Y' ? '개인' : '부점' }}</span></td>
                      <td><span class="td-text">{{ updateTrnctAply.accDeptNm }} ・ {{ updateTrnctAply.aplcntJbgd }} ・ {{ updateTrnctAply.aplcntNm }}</span></td>
                      <td><span class="td-text">{{ updateTrnctAply.trnctNm }}</span></td>
                      <td><span class="td-text">{{ updateTrnctAply.rsvtAplyDd }}  ({{ getDayOfWeek(updateTrnctAply.rsvtAplyDd) }})</span></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- facility-resve-section -->
              <div class="facility-resve-section section-divider">
                <div class="kb-form-fields">
                  <!-- kb-form-row:입소인원 선택 -->
                  <div class="kb-form-row kb-form-field-np">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">입소인원 선택</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <label class="kb-form-label kb-form-column-title">
                        <span class="kb-form-label-text">남자</span>
                      </label>
                    </div>
                    <div class="kb-form-column" style="display: flex;align-items: baseline;">
                      <input  v-model="updateTrnctAply.mlePeopl" type="text" class="kb-form-control" placeholder="" /><span>명</span>
                    </div>
                    <div class="kb-form-column">
                      <label class="kb-form-label kb-form-column-title">
                        <span class="kb-form-label-text">여자</span>
                      </label>
                    </div>
                    <div class="kb-form-column" style="display: flex;align-items: baseline;">
                      <input  v-model="updateTrnctAply.femPeopl" type="text" class="kb-form-control" placeholder="" /><span>명</span>
                    </div>
                    <div class="kb-form-column justify-end">
                      <span class="kb-form-text kb-form-text-sm text-muted">총 인원 6명</span>
                    </div>
                  </div>
                  <!-- kb-form-row:신청 숙실 수 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">신청 숙실 수</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input v-model="updateTrnctAply.useRoomCnt" type="number" class="kb-form-control" placeholder="신청 숙실 수를 입력하세요." />
                    </div>
                  </div>
                  <!-- kb-form-row:이용기간 선택 -->
                  <div class="kb-form-row kb-form-field-period">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">이용기간 선택</span>
                      </label>
                    </div>
                    <div class="kb-form-column flex-fill">
                      <div class="kb-form-dropdown" :class="{'is-active': toggles.periodOpen}">
                        <button type="button" class="kb-form-dropdown-btn" @click="openPeriod"><span class="text">숙박날짜</span><i class="icon-arrow"></i></button>
                        <div class="kb-form-dropdown-options">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li class="dropdown-option-item" v-for="item in usePeridOption" :key="item.value">
                                <a href="javascript:" class="dropdown-option-link" @click="selectUsePeriod(item)">
                                  <span class="dropdown-option-text">{{ item.label }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="kb-form-column">
                      <span>{{data.display}}</span>
                    </div>
                  </div>
                  <!-- kb-form-row:서무직원번호 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">서무직원번호</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input v-model="updateTrnctAply.mblTelno" type="tel" class="kb-form-control" placeholder="직원번호를 입력하세요." />
                    </div>
                  </div>
                  <!-- kb-form-row:비상연락처 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">비상연락처</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input v-model="updateTrnctAply.emerCt" type="tel" class="kb-form-control" placeholder="비상 연락처를 입력하세요." />
                    </div>
                  </div>
                </div>
              </div>
              <!-- facility-resve-section -->
              <div class="facility-resve-section section-divider">
                <div class="segment-box textarea-box">
                  <p class="textarea-title">신청사유</p>
                  <textarea v-model="updateTrnctAply.aplyRsn" rows="10" class="kb-form-control" placeholder="신청사유를 입력해 주세요.">기업지원부문의 워크샵을 위해 신청합니다.</textarea>
                </div>
                <div class="segment-box textarea-box mt-3">
                  <p class="textarea-title">특이사항</p>
                  <textarea v-model="updateTrnctAply.mngrMemoCn" rows="10" class="kb-form-control" placeholder="특이사항을 입력해 주세요.">비흡연자가 많으니 담배냄새는 절대 사절입니다.</textarea>
                </div>
              </div>
            </div>
          </div>
          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-outline kb-btn-lg" type="button" @click="closePop('store')"><span class="text">닫기</span></button>
            <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="setUpdateTrnctAply"><span class="text">수정</span></a>
          </div>
        </form>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" type="button" @click="data.isStorePop = false"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- end:popup-container -->

  <!-- begin:popup-container 신청취소-->
  <div class="popup-container" :class="{'is-active': data.isCancelPop}">
    <!-- popup -->
    <div class="popup">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <form action="">
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">신청 취소</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <div class="facility-resve">
              <!-- facility-resve-section -->
              <div class="facility-resve-section">
                <div class="segment-box textarea-box">
                  <p class="textarea-title">취소사유</p>
                  <textarea v-model="updateTrnctAply.cancelDesc" rows="10" class="kb-form-control" placeholder="취소사유를 입력해 주세요."></textarea>
                </div>
                <div class="segment-box-bottom">
                  <p class="text-icon text text-muted">입소 3영업일 전까지는 취소가 가능하며 이후 취소시에는 연수원 담당자 앞 개별 요청 해주셔야 합니다.</p>
                </div>
              </div>
            </div>
          </div>
          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button type="button" class="kb-btn kb-btn-outline kb-btn-lg" @click="closePop('cancel')"><span class="text">닫기</span></button>
            <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="cancelTrnctAply"><span class="text">취소 신청</span></a>
          </div>
        </form>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" type="button" @click="closePop('cancel')"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- end:popup-container -->

</template>

<script>

import {
  ACT_CANCEL_TRNCT_PLAY,
  ACT_GET_TRNCT_APLY,
  ACT_GET_TRNCT_OPER_YMD_CHECK,
  ACT_UPDATE_TRNCT_APLY
} from "@/store/modules/trnct/trnct";
import {
  getItems,
  isSuccess,
  lengthCheck,
  isTelFormat,
  isHpTelFormat,
  getPreviousBusinessDay,
  dateToDateFormat
} from "@/assets/js/util";
import {useStore} from "vuex";
import {ref, reactive, onMounted, watch } from 'vue';
import {useAlert} from "@/assets/js/modules/common/alert";
import KbCalender from "@/components/common/KbCalender";

export default {
  name: 'HistoryComponent',
  components: {
    KbCalender

  },
  props:{

  },
  setup() {

    const {showMessage,showConfirm} = useAlert();
    const store = useStore();
    const items = ref([]);
  

    // eslint-disable-next-line no-unused-vars
    const usePeridOption = ref([]);

    const familyList = ref([]);
    familyList.value.push({ label : "관계 선택", code : null});
    familyList.value.push({ label : "본인", code : "A1"});
    familyList.value.push({ label : "배우자", code : "A2"});
    familyList.value.push({ label : "자녀", code : "A3"});
    familyList.value.push({ label : "본인(부)", code : "A4"});
    familyList.value.push({ label : "본인(모)", code : "A5"});
    familyList.value.push({ label : "배우자(부)", code : "A6"});
    familyList.value.push({ label : "배우자(모)", code : "A7"});
    familyList.value.push({ label : "조부", code : "A8"});
    familyList.value.push({ label : "조모", code : "A9"});

    const schedule = ref({
      startDt: '',
      endDt: ''
    });

    watch(() => schedule.value, () => {
      console.log(schedule.value);

      getTrnctOperYmdCheck({
        trnctMstSn : updateTrnctAply.trnctMstSn,
        rsvtAplyDd : schedule.value.startDt.ymdformat,
        prsnBrcYn : updateTrnctAply.prsnBrcYn,
        roomTy : updateTrnctAply.roomTy
      })
    });

    const data = reactive({
      isPersonalPop : false,
      isStorePop : false,
      isCancelPop : false,
      isCancelConfirmPop: false,
      isSportPop: false,
      display : null
    })

    const updateTrnctAply = reactive({
      trnctAplySn : null,
      prsnBrcYn : null,
      aplcntId : null,
      aplcntJbgd: null,
      aplcntNm: null,
      accDeptNm: null,
      aplcntDeptNm : null,
      rsvtAplyDd: null,
      trnctNm: null,
      roomTy: null,
      emerCt: null,
      carNo : null,
      mblTelno: null,
      useRoomCnt : null,
      mlePeopl: null,
      femPeopl: null,
      usePerid: null,
      tempUsePerid: null,
      aplyRsn: null,
      dmndCn: null,
      aplyDtl : [
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        }
      ],
      cancelDesc: null,
      stbyYn : 'N',
      trnctMstSn : null,
      tempRsvtAplyDd : null
    })

    const toggles = reactive({
      periodOpen: false,
      aply1 : false,
      aply2 : false,
      aply3 : false,
      aply4 : false,
      aply5 : false,
    });

    watch(updateTrnctAply.aplyDtl, () => {
      updateTrnctAply.aplyPeopl = updateTrnctAply.aplyDtl.filter( (v)=> {return ( v.cpnnNm != null && v.cpnnNm != '') } ).length
    });

    const getRelName = (code) => {
      //본인, 배우자, 자녀, 본인(부), 본인(모),배우자(부),배우자(모),조부,조모
      if(code == 'A1'){
        return '본인'
      }else if(code == 'A2'){
        return '배우자'
      }else if(code == 'A3'){
        return '자녀'
      }else if(code == 'A4'){
        return '본인(부)'
      }else if(code == 'A5'){
        return '본인(모)'
      }else if(code == 'A6'){
        return '배우자(부)'
      }else if(code == 'A7'){
        return '배우자(모)'
      }else if(code == 'A8'){
        return '조부'
      }else if(code == 'A9'){
        return '조모'
      }else{
        return '관계 선택'
      }
    }

    const selectUsePeriod = (usePeriod) => {

      if(usePeriod.value == 0){
        return;
      }

      if(schedule.value.startDt.ymdformat == updateTrnctAply.tempRsvtAplyDd){
        if(usePeriod.value > updateTrnctAply.tempUsePerid){
          showMessage('일정을 변경할 수 없습니다.');
          return;
        }
      }

      toggles.periodOpen = false;
      updateTrnctAply.usePerid = usePeriod.value;
      data.display = usePeriod.label;
    }

    const openPeriod = () => {
      if(toggles.periodOpen === true){
        toggles.periodOpen = false;
      } else{
        toggles.periodOpen = true;
      }
    }

    const getTrcntAply = () => {
      items.value = [];
      store.dispatch(`trnct/${ACT_GET_TRNCT_APLY}`, {
        pageNo: 1,
        pageSize: 3,
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
        }
      }).catch(e => {
        console.error(e);
      });
    }

    // eslint-disable-next-line no-unused-vars
    const detailTrcntAply = (item) => {

      /*showMessage('신청기간이 마감되어 수정 및 삭제가 불가능합니다. 연수관리자에게 문의바랍니다.');
      return;*/
      //2주동안 임시로 수정기능을 막는다


      updateTrnctAply.trnctAplySn = item.trnctAplySn;
      updateTrnctAply.prsnBrcYn = item.prsnBrcYn;
      updateTrnctAply.aplcntId = item.aplcntId;
      updateTrnctAply.aplcntJbgd= item.aplcntJbgd;
      updateTrnctAply.aplcntNm= item.aplcntNm;
      updateTrnctAply.accDeptNm= item.accDeptNm;
      updateTrnctAply.aplcntDeptNm= item.aplcntDeptNm;
      updateTrnctAply.rsvtAplyDd= item.rsvtAplyDd;
      updateTrnctAply.trnctNm= item.trnctNm;
      updateTrnctAply.roomTy= item.roomTy;
      updateTrnctAply.emerCt= item.emerCt;
      updateTrnctAply.carNo = item.carNo;
      updateTrnctAply.mblTelno= item.mblTelno;
      updateTrnctAply.useRoomCnt = item.useRoomCnt;
      updateTrnctAply.mlePeopl= item.mlePeopl;
      updateTrnctAply.femPeopl= item.femPeopl;
      updateTrnctAply.usePerid= item.usePerid;
      updateTrnctAply.tempUsePerid= item.usePerid;
      updateTrnctAply.tempRsvtAplyDd= item.rsvtAplyDd;
      updateTrnctAply.aplyRsn= item.aplyRsn;
      updateTrnctAply.dmndCn= item.dmndCn;
      updateTrnctAply.trnctMstSn = item.trnctMstSn;

      updateTrnctAply.aplyPeopl = item.aplyPeopl;
      updateTrnctAply.stbyYn = item.stbyYn;

      for(let i = 0 ; i < item.aplyDtl.length ;i++){
        updateTrnctAply.aplyDtl[i].cpnnNm = item.aplyDtl[i].cpnnNm;
        updateTrnctAply.aplyDtl[i].aplcntRel = item.aplyDtl[i].aplcntRel;
      }
      if(item.prsnBrcYn == 'Y'){ //개인
        data.isPersonalPop = true;
      }else{
        data.isStorePop = true;
      }

      getTrnctOperYmdCheck(item);
    }

    // eslint-disable-next-line no-unused-vars
    const getTrnctOperYmdCheck = async (item) => {
      await store.dispatch(`trnct/${ACT_GET_TRNCT_OPER_YMD_CHECK}`,{
        trnctMstSn : item.trnctMstSn,
        rsvtAplyDd : item.rsvtAplyDd,
        prsnBrcYn : item.prsnBrcYn,
        roomTy : item.roomTy
      }).then(res => {
        if(lengthCheck(res)){
          console.log('getTrnctOperYmdCheck...',res);
          usePeridOption.value = [];
          for(let i = 0 ; i < res.items.length ;i++){
            usePeridOption.value.push(res.items[i]);
          }


          let selectOption = usePeridOption.value.filter( (v) => {
            return v.value == item.usePerid
          })

          if(selectOption.length != 0){
            data.display = selectOption[0].label;
          }
        }
      }).catch((e) => {console.error(e);})
    }

    const getDayOfWeek = (_date) => {
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      const dayOfWeek = week[new Date(_date).getDay()];
      return dayOfWeek;
    }

    const clearParams = () => {
      updateTrnctAply.trnctAplySn = null;
      updateTrnctAply.prsnBrcYn = null;
      updateTrnctAply.aplcntId = null;
      updateTrnctAply.aplcntJbgd= null;
      updateTrnctAply.aplcntNm= null;
      updateTrnctAply.accDeptNm= null;
      updateTrnctAply.rsvtAplyDd= null;
      updateTrnctAply.trnctNm= null;
      updateTrnctAply.roomTy= null;
      updateTrnctAply.emerCt= null;
      updateTrnctAply.carNo = null;
      updateTrnctAply.mblTelno= null;
      updateTrnctAply.useRoomCnt = null;
      updateTrnctAply.mlePeopl= null;
      updateTrnctAply.femPeopl= null;
      updateTrnctAply.usePerid= null;
      updateTrnctAply.aplyRsn= null;
      updateTrnctAply.dmndCn= null;
      updateTrnctAply.aplyPeopl= null;
      updateTrnctAply.stbyYn = 'N';
      updateTrnctAply.aplyDtl = [
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null //동반자 명
        }
      ]
    }

    const closePop = (pop) => {
      clearParams();
      if(pop == 'personal'){
        data.isPersonalPop = false;
      }else if(pop == 'personal'){
        data.isStorePop = false;
      }else{
        updateTrnctAply.cancelDesc = null;
        data.isCancelPop = false;
      }
    }

    const changeTrnctDt = (value) =>{
     
     updateTrnctAply.rsvtAplyDd = value.startDt?.ymdformat;
     if(updateTrnctAply.rsvtAplyDd) getTrnctOperYmdCheck(updateTrnctAply);
    //debugger // eslint-disable-line
     
   }    

   const fnValidation=()=>{           
        if (updateTrnctAply.emerCt == "" || updateTrnctAply.emerCt == null) {
          showMessage('비상연락처를 입력해주세요');
          return false;
        }

        if(!isTelFormat(updateTrnctAply.emerCt) && !isHpTelFormat(updateTrnctAply.emerCt) ){
           showMessage('비상연락처가 잘못되었습니다.');
          return false;          
        }            
    
      return true;
  }

    const setUpdateTrnctAply = async() => {
      if(!fnValidation()) return;
      if(updateTrnctAply.prsnBrcYn == 'N'){

        if(updateTrnctAply.mlePeopl == null || updateTrnctAply.mlePeopl == ''){
          updateTrnctAply.mlePeopl = 0;
        }

        if(updateTrnctAply.femPeopl == null || updateTrnctAply.femPeopl == ''){
          updateTrnctAply.femPeopl = 0;
        }
        updateTrnctAply.aplyPeopl = parseInt(updateTrnctAply.mlePeopl) + parseInt(updateTrnctAply.femPeopl);
      }

      //updateTrnctAply.rsvtAplyDd = schedule.value.startDt.ymdformat;
      showConfirm({
          text: '수정 하시겠습니까?',
          callback: async () => {            

            await store.dispatch(`trnct/${ACT_UPDATE_TRNCT_APLY}`, updateTrnctAply).then(res => {
              if (isSuccess(res)) {
                clearParams();
                data.isPersonalPop = false;
                data.isStorePop = false;
                showMessage('수정 되었습니다.');
                getTrcntAply();
              }
            }).catch((e) => {console.error(e);})
          }
        });            
    }

    //const openCancelPop = (item) => {
    const openCancelPop = (item) => {
      //showMessage('신청기간이 마감되어 수정 및 삭제가 불가능합니다. 연수관리자에게 문의바랍니다.');
      //2주동안 임시로 수정기능을 막는다
      updateTrnctAply.trnctAplySn = item.trnctAplySn;
      updateTrnctAply.stbyYn = item.stbyYn;
      data.isCancelPop = true;
    }

    const alertThreeday = () =>{
      showMessage('3영업일 이내 수정/취소 하실 수 없습니다.');
      return;
    }
    const cancelTrnctAply = async () => {
      showConfirm({
            text: '취소 하시겠습니까?',
            callback: async () => {          
        await store.dispatch(`trnct/${ACT_CANCEL_TRNCT_PLAY}`, updateTrnctAply).then(res => {
          if (isSuccess(res)) {
            getTrcntAply();
            console.log("#####cancelTrnctAply######")            ;
            data.isCancelPop = false;
            data.isCancelConfirmPop = true;
            updateTrnctAply.cancelDesc = null;
            
          }
        }).catch((e) => {console.error(e);})
        }
      });
    }

    const checkThreeDay = (item) => {

      if(item.rsvtAplyDd != undefined) {
        const masTime = new Date(item.rsvtAplyDd);
        // 당일포함 3영업일이므로 days는 2로 지정
        let limitDay = getPreviousBusinessDay(masTime, 2, 'yyyy-MM-dd');
        const todayTime = dateToDateFormat(new Date(), 'yyyy-MM-dd');

        return todayTime < limitDay;
      }

      // const masTime = new Date(item.rsvtAplyDd);
      // const todayTime = new Date();
      // const diff = masTime - todayTime;
      //
      // const diffDay = Math.floor(diff / (1000*60*60*24));
      // console.log(diffDay);
      //
      // if(diffDay <= 2){
      //   return false;
      // }
      // return true;
    }

    const openAply = (num) => {
      console.log(num);
      if(toggles[`aply${num}`] === true){
        toggles[`aply${num}`] = false;
      }else{
        toggles[`aply${num}`] = true;
      }
    }

    const selectAply = (num, type) => {
      toggles[`aply${num}`] = false;


      if(type != "" || type != null){
        updateTrnctAply.aplyDtl[num].aplcntRel = type;
      }
      toggles.aply1 = false;
      toggles.aply2 = false;
      toggles.aply3 = false;
      toggles.aply4 = false;
      toggles.aply5 = false;
    }

    onMounted(() => {
      getTrcntAply();
    });

    return {
      getTrcntAply,
      items,
      detailTrcntAply,
      updateTrnctAply,
      data,
      getDayOfWeek,
      toggles,
      selectUsePeriod,
      openPeriod,
      closePop,
      setUpdateTrnctAply,
      openCancelPop,
      cancelTrnctAply,
      checkThreeDay,
      usePeridOption,
      openAply,
      selectAply,
      familyList,
      getRelName,
      schedule,
      changeTrnctDt,
      alertThreeday
    }
  }
};
</script>
