<template>
  <article class="content-section section-margin">
    <header class="section-header">
      <h4 class="title">신청현황</h4>
    </header>
    <div class="kb-table-list-container">
      <div class="list-top pt-0">
        <div class="top-column"><span class="subtext">전체 {{items.length}}건</span></div>
        <div class="top-column">
          <!--                <a href="" class="link">신청내역 바로가기 <i class="icon-link"></i></a>-->
          <router-link to="/daily/history" class="more-link">신청내역 바로가기 <i class="icon-link"></i></router-link>
        </div>
      </div>
      <div class="kb-table kb-table-bordered kb-table-bordered-min">
        <table>
          <colgroup>
            <col style="width:60px;">
            <col style="width:auto;">
            <col style="width:160px;">
            <col style="width:220px;">
            <col style="width:190px;">
            <col style="width:100px;">
            <col style="width:240px;">
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">구분</span></th>
            <th><span class="th-title">시설명</span></th>
            <th><span class="th-title">상세</span></th>
            <th><span class="th-title">신청기간</span></th>
            <th><span class="th-title">신청일시</span></th>
            <th><span class="th-title">신청상태</span></th>
            <th class="text-center"><span class="th-title">기능</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item) in items" :key="item.fcltRsvtAplySn">
            <td><span class="td-text">체육시설</span></td>
            <td><strong class="td-text">{{ item.fcltDivNm }}</strong></td>
            <td><span class="td-text"></span></td>
            <td><span class="td-text">{{ item.aplyYmd }} - {{ getSportTime(item)}} </span></td>
            <td><span class="td-text">{{ item.regDt }}</span></td>
            <td><strong class="td-text text-yellow">{{ item.allocSttYn == 'Y' ? '배정완료' : '신청중' }}</strong></td>
            <td>
              <div class="td-actions">
                <button class="kb-btn kb-btn-outline rounded-lg" type="button" @click="detailFcltRsvtAply(item)"><strong class="text">상세</strong></button>
                <button class="kb-btn kb-btn-outline rounded-lg" type="button" @click="cancelFcltAply(item)"><strong class="text">취소</strong></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- begin:popup-container 시설 사용-->
    <div class="popup-container" :class="{'is-active': data.isSportPop}">
      <!-- popup -->
      <div class="popup">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
          <form action="">
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">시설 사용 신청내역</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">
              <div class="facility-resve">
                <!-- facility-resve-section -->
                <div class="facility-resve-section">
                  <div class="kb-table kb-table-striped">
                    <table>
                      <colgroup>
                        <col />
                      </colgroup>
                      <thead>
                      <tr>
                        <th><span class="th-title">체육시설</span></th>
                        <th><span class="th-title">이용일시</span></th>
                        <th><span class="th-title">신청일시</span></th>
                        <th><span class="th-title">최종수정일시</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><span class="td-text">일산 운동장</span></td>
                        <td><span class="td-text">{{ updateFcltRsvtAply.aplyYmd }} ・ {{ getSportTime(updateFcltRsvtAply) }}</span></td>
                        <td><span class="td-text">{{ updateFcltRsvtAply.regDt }}</span></td>
                        <td><span class="td-text">{{ updateFcltRsvtAply.mdfcnDt }}</span></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- facility-resve-section -->
                <div class="facility-resve-section section-divider">
                  <div class="kb-form-fields">
                    <!-- kb-form-row:신청자 1 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청자 1</span>
                        </label>
                      </div>
                      <div class="kb-form-column d-flex align-items-center">
                        <strong class="kb-form-text">{{ updateFcltRsvtAply.aplcntDeptNm }} ・ {{ updateFcltRsvtAply.aplcntNm }} | {{ updateFcltRsvtAply.aplcntId }} </strong>
                      </div>
                    </div>
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청자 2</span>
                        </label>
                      </div>
                      <div class="kb-form-column column-whole kb-form-search">
                        <input @keyup.enter="searchUser" v-model="userSearchParam.searchText" type="text" class="kb-form-control" placeholder="직번을 입력하세요." />
                        <button type="button" class="kb-form-search-btn" @click="searchUser"><i class="icon-search"></i></button>
                      </div>
                    </div>

                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청자 2 정보</span>
                        </label>
                      </div>
                      <div class="kb-form-column d-flex align-items-center">
                        <strong class="kb-form-text">{{ updateFcltRsvtAply.aplcntDeptNm2 }} ・ {{ updateFcltRsvtAply.aplcntNm2 }} | {{ updateFcltRsvtAply.aplcntId2 }} </strong>
                      </div>
                    </div>
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청자 2 생년월일</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input type="text" maxlength="6" v-model="updateFcltRsvtAply.brdt2" class="kb-form-control w-312"
                        placeholder="생년월일 6자리를 입력하세요." />
                      </div>
                    </div>    


                    <!-- kb-form-row:이용인원 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">이용인원</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input v-model="updateFcltRsvtAply.aplyPeopl" type="number" class="kb-form-control" placeholder="이용인원을 입력하세요." />
                      </div>
                    </div>
                    <!-- kb-form-row:사무실전화번호 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청자 1 연락처</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input v-model="updateFcltRsvtAply.aplcntTelno" type="text" class="kb-form-control" placeholder="신청자 1 연락처 입력해주세요." />
                      </div>
                    </div>
                    <!-- kb-form-row:휴대폰전화번호 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청자 2 연락처</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input v-model="updateFcltRsvtAply.aplcntTelno2" type="text" class="kb-form-control" placeholder="신청자 2 연락처 입력해주세요." />
                      </div>
                    </div>
                     
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">부점/단체명</span>
                        </label>
                      </div>
                      <div class="kb-form-column w-288">
                        <div class="kb-form-dropdown" :class="{'is-active': toggles.grpToggle}">
                          <button type="button" @click="openGrpToggle" class="kb-form-dropdown-btn"><span class="text">{{ updateFcltRsvtAply.grpDivNm == null ? '단체구분' : updateFcltRsvtAply.grpDivNm }}</span><i class="icon-arrow"></i></button>
                          <div class="kb-form-dropdown-options">
                            <div class="dropdown-option">
                              <ul class="dropdown-option-list">
                                <li class="dropdown-option-item" v-for="item in codeList" :key="item.cdSn">
                                  <a @click="selectGrp(item)" href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">{{ item.cdNm }}</span></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="kb-form-column w-288" v-show="toggles.clubToggle==false || toggles.grpOrClub == true">
                        <input type="text" class="kb-form-control" v-model="updateFcltRsvtAply.grpNm" placeholder="" @click="openClubToggle" />
                      </div> 
                      <div class="kb-form-column w-288" v-show="toggles.clubToggle==true && toggles.grpOrClub == false">
                        <div class="kb-form-dropdown is-active">
                          <button type="button" @click="openClubToggle" class="kb-form-dropdown-btn"><span class="text">{{
                            updateFcltRsvtAply.grpNm == null ? '둥호회 선택' : updateFcltRsvtAply.grpNm }}</span><i
                              class="icon-arrow"></i></button>
                          <div class="kb-form-dropdown-options">
                            <div class="dropdown-option">
                              <ul class="dropdown-option-list">
                                <li class="dropdown-option-item" v-for="item in clubList" :key="item.code">
                                  <a @click="selectClub(item)" href="javascript:" class="dropdown-option-link"><span
                                      class="dropdown-option-text">{{ item.label }}</span></a>
                                </li>
                              </ul>
                            </div>
                          </div>                      
                        </div>
                      </div>                      
                    </div>
                  </div>
                  <div class="kb-form-fields-bottom">
                    <div class="text-notice"><strong class="text">허위일 경우 향후 6개월간 이용이 제한됩니다</strong></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <button type="button" @click="cancelFcltRsvtAplyPop" class="kb-btn kb-btn-outline kb-btn-lg"><span class="text">취소</span></button>
              <a @click="fnBeforeUpdate" href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg"><span class="text">수정</span></a>
            </div>
          </form>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button @click="cancelFcltRsvtAplyPop" class="kb-btn-popup-close" type="button"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->

    <!-- [begin::alert-container] -->
    <div class="kb-alert-container" :class="{'is-active': data.isCancelAlertPop}">
      <div class="kb-alert">
        <div class="kb-alert-contents pt-5">
          <strong class="kb-alert-title">신청취소</strong>
          <p class="kb-alert-text text-center">신청이 정상 취소되었습니다. <br/>감사합니다.</p>
        </div>
        <div class="kb-alert-actions">
          <button class="kb-btn-alert" type="button" @click="data.isCancelAlertPop = false"><span class="text">확인</span></button>
        </div>
      </div>
    </div>
    <!-- [end::alert-container] -->

  </article>

</template>

<script>

import {
  ACT_CANCEL_FCLT_RSVT_APLY,
  ACT_GET_FCLT_APLY, ACT_GET_USER_LIST, ACT_UPDATE_FCLT_RSVT_APLY
} from "@/store/modules/trnct/trnct";
import {getItems, isSuccess, lengthCheck,isTelFormat,isHpTelFormat} from "@/assets/js/util";
import {useStore} from "vuex";
import {ref, reactive, onMounted, computed,watch } from 'vue';
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'HistorySportsComponent',
  components: {

  },
  props:{

  },
  setup() {

    const store = useStore();
    const {showConfirm , showMessage} = useAlert();
    const clubList = ref([]);

    const items = ref([{}]);

    const data = reactive({
      isSportPop: false,
      isCancelPop : false,
      isCancelAlertPop : false,
    })

    const toggles = reactive({
      grpToggle : false

    });

    const openGrpToggle = () => {
      if(toggles.grpToggle === true){
        toggles.grpToggle = false;
      } else{
        toggles.grpToggle = true;
      }
    }

    const selectGrp = (item) => {
      updateFcltRsvtAply.grpDivCdDcd = item.cd
      updateFcltRsvtAply.grpDivNm =item.cdNm;
      toggles.grpToggle = false;
      //당행동호회를 클릭시
      if (item.cd == '2095004') {
        //
        toggles.grpOrClub = false;

      } else {
        toggles.grpOrClub = true;
      }      
    }

    const search = reactive({
      //trnctNm: null, //연수원 명
      //prsnBrcYn: null, //개인 부점 구분 Y는 개인 N은 부점
      rsvtAplyDdStart: null, //예약 신청일
      rsvtAplyDdEnd: null,
    });

    const updateFcltRsvtAply = reactive({
      fcltRsvtAplySn : null,
      aplyYmd : null,
      aplcntId : null,
      aplcntNm : null,
      aplcntDeptNm : null,
      aplcntId2 : null,
      aplcntNm2 : null,
      aplcntDeptCd2 : null,
      aplcntDeptNm2 : null,
      
      grpNm : null,
      aplyPeopl : null,
      part01RsvtYn : null,
      part02RsvtYn : null,
      part03RsvtYn : null,
      part04RsvtYn : null,
      part05RsvtYn : null,
      regDt : null,
      mdfcnDt : null,
      brdt : null,
      brdt2 : null,
      aplcntTelno : null,
      aplcntTelno2 :null,
      grpDivCdDcd : null,
      grpDivNm : null
    });

    const codeList = computed(() =>{
      const array = [];
      array.push({ cd: '', cdNm: '단체구분' });
      array.push(...store.state.code.codes.filter(code => code.upCd === '2095000' && code.cdNm === '당행 동호회'));
      //debugger // eslint-disable-line
      return  array;
    });

    const closePop = () => {
      data.isCancelPop = false;
    }

    const fnValidation=()=>{
      
      
        if (updateFcltRsvtAply.aplcntId2 == null || updateFcltRsvtAply.aplcntId2 == '' ) {
          showMessage('신청자2를 선택해주세요.');
          return;
        }

        if (updateFcltRsvtAply.aplyPeopl == null || updateFcltRsvtAply.aplyPeopl == '' || updateFcltRsvtAply.aplyPeopl == 0) {
          showMessage('이용인원을 입력해주세요');
          return false;
        }
        /*
        if (updateFcltRsvtAply.grpDivCdDcd == null || updateFcltRsvtAply.grpDivCdDcd == '') {
          showMessage('단체를 선택해주세요');
          return false;
        }*/

        if (updateFcltRsvtAply.aplcntTelno == null || updateFcltRsvtAply.aplcntTelno == '') {
          showMessage('신청자 1 연락처를 입력해주세요');
          return false;
        }

        if(!isTelFormat(updateFcltRsvtAply.aplcntTelno) && !isHpTelFormat(updateFcltRsvtAply.aplcntTelno) ){
          showMessage('신청자 1 연락처가 잘못되었습니다.');
          return false;          
        }        


        if (updateFcltRsvtAply.aplcntTelno2 == null || updateFcltRsvtAply.aplcntTelno2 == '') {
          showMessage('신청자 2 연락처를 입력해주세요');
          return false;
        }

        if(!isTelFormat(updateFcltRsvtAply.aplcntTelno2) && !isHpTelFormat(updateFcltRsvtAply.aplcntTelno2) ){
          showMessage('신청자 2 연락처가 잘못되었습니다.');
          return false;          
        }                

        if (updateFcltRsvtAply.aplcntTelno == updateFcltRsvtAply.aplcntTelno2) {
          showMessage('신청자 1, 신청자 2 연락처를 동일하게 입력할 수 없습니다.');
          return false;
        }        

     
        
        if (updateFcltRsvtAply.grpNm == null || updateFcltRsvtAply.grpNm == '') {
          showMessage('단체명을 입력해주세요.');
          return false;
        }
        return true;

    }

    const detailFcltRsvtAply = (item) => {
      toggles.clubToggle=false;
      
      data.isSportPop = true;
      userSearchParam.searchText = null;
      updateFcltRsvtAply.fcltRsvtAplySn = item.fcltRsvtAplySn;
      updateFcltRsvtAply.aplyYmd = item.aplyYmd;
      updateFcltRsvtAply.aplcntId = item.aplcntId;
      updateFcltRsvtAply.aplcntNm = item.aplcntNm;
      updateFcltRsvtAply.aplcntDeptNm = item.aplcntDeptNm;
      updateFcltRsvtAply.aplcntId2 = item.aplcntId2;
      updateFcltRsvtAply.aplcntNm2 = item.aplcntNm2;
      updateFcltRsvtAply.aplcntDeptNm2 = item.aplcntDeptNm2;
      updateFcltRsvtAply.grpDivCdDcd = item.grpDivCdDcd;
      updateFcltRsvtAply.grpDivNm = item.grpDivNm;
      updateFcltRsvtAply.grpNm = item.grpNm;
      updateFcltRsvtAply.aplyPeopl = item.aplyPeopl;
      updateFcltRsvtAply.part01RsvtYn = item.part01RsvtYn;
      updateFcltRsvtAply.part02RsvtYn = item.part02RsvtYn;
      updateFcltRsvtAply.part03RsvtYn = item.part03RsvtYn;
      updateFcltRsvtAply.part04RsvtYn = item.part04RsvtYn;
      updateFcltRsvtAply.part05RsvtYn = item.part05RsvtYn;
      updateFcltRsvtAply.regDt = item.regDt;
      updateFcltRsvtAply.mdfcnDt = item.mdfcnDt;
      updateFcltRsvtAply.aplcntTelno = item.aplcntTelno;
      updateFcltRsvtAply.aplcntTelno2 = item.aplcntTelno2;
      updateFcltRsvtAply.brdt = item.brdt;
      updateFcltRsvtAply.brdt2 = item.brdt2;
      
    }

    const cancelFcltRsvtAplyPop = () =>{
      data.isSportPop = false;
    }

    const openCancelPop2 = (item) => {
      updateFcltRsvtAply.fcltRsvtAplySn = item.fcltRsvtAplySn;
      data.isCancelPop = true;
    }

    const getFcltAply = () => {
      console.log('체육시설 검색');
      items.value = [];
      store.dispatch(`trnct/${ACT_GET_FCLT_APLY}`, {
        rsvtAplyDdStart: search.rsvtAplyDdStart,
        rsvtAplyDdEnd: search.rsvtAplyDdEnd,
        pageNo: 1,
        pageSize: 3,
      }).then(res => {
        console.log("체육시설 검색완료");
        console.log(res);
        if (lengthCheck(res)) {
          console.log(res);
          items.value = getItems(res);
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const fnBeforeUpdate = async () => {
        if(!fnValidation()) return;
        setUpdateFcltRsvtAply();

    }

    const setUpdateFcltRsvtAply = async () => {


      showConfirm({
        text: '수정 하시겠습니까?',
        callback: async () => {      
          await store.dispatch(`trnct/${ACT_UPDATE_FCLT_RSVT_APLY}`, updateFcltRsvtAply).then(res => {
            if (isSuccess(res)) {
              showMessage('수정 되었습니다.', ()=>{data.isSportPop = false;
                getFcltAply();}
              );
            }else{
              showMessage('<span class="text-danger">!!! 수정할 수 없습니다 !!!</span><br/>신청자2의 신청제한 이나 동일 동호회 이용제한 등을 확인 해 주세요', ()=>{
                data.isSportPop = false;
                getFcltAply();
              });
            }
          }).catch((e) => {console.error(e);})
        }
      });          
    }

  watch(() => updateFcltRsvtAply.aplcntTelno, () => {
    updateFcltRsvtAply.aplcntTelno = (updateFcltRsvtAply.aplcntTelno==null? "": updateFcltRsvtAply.aplcntTelno).replace(/[^0-9]/g, '')
  })

  watch(() => updateFcltRsvtAply.aplcntTelno2, () => {      
    updateFcltRsvtAply.aplcntTelno2 = (updateFcltRsvtAply.aplcntTelno2==null? "": updateFcltRsvtAply.aplcntTelno2).replace(/[^0-9]/g, '')        
  })

  watch(() => updateFcltRsvtAply.brdt, () => {      
    updateFcltRsvtAply.brdt = (updateFcltRsvtAply.brdt==null? "": updateFcltRsvtAply.brdt).replace(/[^0-9]/g, '')        
  })    

  watch(() => updateFcltRsvtAply.brdt2, () => {      
    updateFcltRsvtAply.brdt2 = (updateFcltRsvtAply.brdt2==null? "": updateFcltRsvtAply.brdt2).replace(/[^0-9]/g, '')        
  })    


  const cancelFcltAply = async (item) => {
    showConfirm({
      text: '취소 하시겠습니까?',
      callback: async () => {
        updateFcltRsvtAply.fcltRsvtAplySn = item.fcltRsvtAplySn;
        await store.dispatch(`trnct/${ACT_CANCEL_FCLT_RSVT_APLY}`, updateFcltRsvtAply).then(res => {
          if (isSuccess(res)) {
            data.isCancelAlertPop = true;
            getFcltAply();
          }
        }).catch((e) => {console.error(e);})
      }
    });
  }

const getSportTime = (item) => {

  if(item.part01RsvtYn == 'Y'){
    return "08:00 - 10:00 (1회차)"
  }else if(item.part02RsvtYn == 'Y'){
    return "10:00 - 12:00 (2회차)"
  }else if(item.part03RsvtYn == 'Y'){
    return "12:00 - 14:00 (3회차)"
  }else if(item.part04RsvtYn == 'Y'){
    return "14:00 - 16:00 (4회차)"
  }else if(item.part05RsvtYn == 'Y'){
    return "16:00 - 18:00 (5회차)"
  }
}

const userList = ref([]);
const userSearchParam = reactive( {
  searchText : null,
  totalCount : 0
});

    const searchUser = () => {

      if(userSearchParam.searchText == null){
        showMessage('직번을 입력해주세요.');
        return;
      }


      if (userSearchParam.searchText == updateFcltRsvtAply.aplcntId) {
        showMessage('신청자1과 다른 직번을 입력해주십시오.');
        userSearchParam.searchText = '';
        return;
      }      

      console.log('유저 검색 버튼 ')
      store.dispatch(`trnct/${ACT_GET_USER_LIST}`, {
        lrnerId: userSearchParam.searchText,
        pageNo: 1,
        pageSize: 100
      }).then(res=>{
        if(lengthCheck(res)){
          userList.value = getItems(res);
          console.log(userList.value)

          updateFcltRsvtAply.aplcntId2 = userList.value[0].lrnerId;
          updateFcltRsvtAply.aplcntNm2 = userList.value[0].lrnerNm;
          updateFcltRsvtAply.aplcntDeptCd2 = userList.value[0].deptCd;
          updateFcltRsvtAply.aplcntDeptNm2 = userList.value[0].deptNm;

        }else{
          showMessage('해당 직번이없습니다.');
          userList.value = [];
          userSearchParam.totalCount = 0;

          updateFcltRsvtAply.aplcntId2 = null;
          updateFcltRsvtAply.aplcntNm2 = null;
          updateFcltRsvtAply.aplcntDeptCd2 = null;
          updateFcltRsvtAply.aplcntDeptNm2 = null;
        }
      }).catch(e=>{
        console.error(e);
      });
    }

    onMounted(() => {
      getFcltAply();
      clubList.value.push({ label: "레전드", code: "레전드" });
      clubList.value.push({ label: "본축회", code: "본축회" });
      clubList.value.push({ label: "일레븐", code: "일레븐" });
      clubList.value.push({ label: "중우회", code: "중우회" });
      clubList.value.push({ label: "까치회", code: "까치회" });
      clubList.value.push({ label: "기타", code: "기타" });          
      
    });

    const openClubToggle = () => {
      console.log("###########openClubToggle#");
      if (toggles.clubToggle === true) {
        toggles.clubToggle = false;
      } else {
        toggles.clubToggle = true;
      }
    }
    const selectClub = (item) => {
      toggles.clubToggle = false;
      updateFcltRsvtAply.grpNm = item.label;
    }    



    return {
      getFcltAply,
      cancelFcltRsvtAplyPop,
      detailFcltRsvtAply,
      updateFcltRsvtAply,
      items,
      data,
      openCancelPop2,
      getSportTime,
      cancelFcltAply,
      setUpdateFcltRsvtAply,
      closePop,
      toggles,
      codeList,
      openGrpToggle,
      selectGrp,
      searchUser,
      userSearchParam,
      fnBeforeUpdate,
      openClubToggle,
      selectClub,
      clubList
    }
  }
};
</script>
