<template>
  <article class="content-section section-divider">
    <header class="section-header">
      <h4 class="title">생활연수 바로가기</h4>
    </header>
    <div class="barlink-wrap">
      <ul class="barlink-list" data-column="2">
        <li class="barlink-item">
          <router-link :to="{path:'/help/faq', query:{pageNo:'1',faqClassCdDcd:'2023025'}}" class="barlink barlink-yellow">
            <i class="icon-symbol-faq"></i>
            <span class="title">생활연수 FAQ</span>
            <span class="arrow"><i class="icon-arrow"></i></span>
          </router-link>
        </li>
        <li class="barlink-item">
          <router-link to="/daily/inquiry" class="barlink barlink-blue">
<!--          <a href="" class="barlink barlink-blue">-->
            <i class="icon-symbol-inquiry"></i>
            <span class="title">생활연수 운영자에게</span>
            <span class="arrow"><i class="icon-arrow"></i></span>
<!--          </a>-->
          </router-link>
        </li>
      </ul>
    </div>
  </article>
</template>

<script>

export default {
  name: 'DirectLinkComponent',
  components: {

  },
  props:{

  },
  setup() {
    return {

    }
  }
};
</script>
