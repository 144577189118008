<template>
  <!-- apply-images -->
  <div class="apply-images" v-if="imageList != null && imageList.length > 0">
    <swiper
        class="image-swiper"
        :loop="true"
        :pagination="{
                el: '.swiper-pagination',
                clickable: true,
              }"
        :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }"
        :scrollbar="{
                el: '.swiper-scrollbar'
              }"
    >
      <swiper-slide v-for="(item, idx) in imageList" :key="idx" >
        <div class="image-slide swiper-slide"><img :src="item.src" :alt="item.title"/></div>
      </swiper-slide>

      <!-- If we need pagination -->
      <div class="swiper-pagination"></div>

      <!-- If we need navigation buttons -->
      <button type="button" class="swiper-button-prev"><i class="icon-prev"></i></button>
      <button type="button" class="swiper-button-next "><i class="icon-next"></i></button>
    </swiper>
  </div>
  <!-- //apply-images -->
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';

export default {
  name: 'ImageSwiperComponent',
  components: {
    Swiper,
    SwiperSlide
  },
  props:{
    imageList: {
      type: Array,
      default: null
    }
  },
  setup() {

    return {

    }
  }
};
</script>