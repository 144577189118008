<template>
  <article class="content-section">
    <header class="section-header">
      <h4 class="title">공지사항</h4>
      <div class="header-sub">
        <router-link to="/daily/notice" class="more-link"> 전체보기</router-link>
      </div>
    </header>
    <div class="notice-list-container">

      <div v-if="items && items.length === 0" class="search-result">
        <div class="result-empty">
          <img src="../../assets/lxp/images/common/img_empty.png">
          <p class="text">검색결과가 없어요</p>
        </div>
      </div>

      <div class="board-list-container">
        <ul class="board-list">
          <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
            <article class="board-row">
              <router-link :to="{path: '/daily/notice/'+item.trnctNtcSn}" class="board-link"></router-link>
              <div v-if="item.topFixYn === 'Y'" class="board-column column-index"><em class="text text-gold">필독</em></div>
              <div v-else class="board-column column-index">{{idx+1+(3*(1-1))}}</div>
              <div v-if="item.ntcFileAtchYn === 'Y'" class="board-column column-file"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>
              <div class="board-column column-title">
                <span class="title">{{item.ntcTitle}}</span>
                <i class="icon-new"></i>
              </div>
              <div class="board-column column-date"><span class="text">{{ getDateFormat('yyyy-MM-dd', item.regDt)}}</span></div>
              <div class="board-column column-hit"><span class="text">{{item.inqCnt}} 조회</span></div>
            </article>
          </li>
<!--          <li class="board-list-item">
            <article class="board-row">
              <a href="" class="board-link"></a>
              <div class="board-column column-index"><em class="text">19</em></div>
              <div class="board-column column-title"><span class="title">속초 ∙ 대천연수원 ㅣ 연수원 생활연수</span></div>
              <div class="board-column column-date"><span class="text">2021.6.10 14:40</span></div>
              <div class="board-column column-hit"><span class="text">200 조회</span></div>
            </article>
          </li>
          <li class="board-list-item">
            <article class="board-row">
              <a href="" class="board-link"></a>
              <div class="board-column column-index"><em class="text">18</em></div>
              <div class="board-column column-title"><span class="title">속초 ∙ 대천연수원 ㅣ 연수원 생활연수</span></div>
              <div class="board-column column-date"><span class="text">2021.6.10 14:40</span></div>
              <div class="board-column column-hit"><span class="text">200 조회</span></div>
            </article>
          </li>-->
        </ul>
      </div>
    </div>
  </article>
</template>

<script>

import {ACT_GET_TRNCT_NOTICE_LIST} from "@/store/modules/trnct/trnct";
import {getItems, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {useStore} from 'vuex';
import {onMounted, ref} from 'vue';

export default {
  name: 'NoticeComponent',
  components: {

  },
  props:{
  },
  emits: ['updatePopupNotice'],
  setup(props, {emit}) {
    const store = useStore();
    const items = ref([{}]);

    const getHelpNoticeList = () => {
      store.dispatch(`trnct/${ACT_GET_TRNCT_NOTICE_LIST}`, {
        pageNo: 1,
        pageSize: 3
      }).then(res => {
        if (lengthCheck(res)) {
          // console.log('asdfasdfsa', res);
          items.value = getItems(res);
          // console.log("item", items.value, "ctnt", items.value[0].ntcCn);

          //popupnoti 여부
          if(res.popupNoti){
            emit('updatePopupNotice',  res.popupNoti[0].ntcCn);
          }

        }
      }).catch(e => {
        console.error(e);
      })
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    onMounted(() => {
      getHelpNoticeList();
    });

    return {
      getHelpNoticeList,
      items,
      getDateFormat
    }
  }
};
</script>
